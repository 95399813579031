import { FC } from "react";

import { Icon, IconProps } from "@spwn-portal/ui/chakra";

export const MyPlanIcon: FC<IconProps> = (props) => {
  return (
    <Icon
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>MyPlanIcon</title>
      <path
        d="M7.875 15.4375V3.0625L9.25 3.75L10.625 3.0625L11.9974 3.75L13.3883 3.0625L14.75 3.75L16.116 3.0625L17.488 3.75L18.875 3.0625L20.2504 3.75L21.625 3.0625V12.6875"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M21.625 12.6875V17.5C21.625 18.4117 21.2629 19.286 20.6182 19.9307C19.9735 20.5753 19.0992 20.9375 18.1875 20.9375M18.1875 20.9375C17.2758 20.9375 16.4015 20.5753 15.7568 19.9307C15.1122 19.286 14.75 18.4117 14.75 17.5V15.4375H3.06253C2.97202 15.4367 2.88226 15.4539 2.79849 15.4882C2.71471 15.5225 2.6386 15.5731 2.5746 15.6371C2.5106 15.7011 2.45999 15.7772 2.42572 15.861C2.39146 15.9447 2.37422 16.0345 2.37503 16.125C2.37503 18.875 2.66464 20.9375 5.81253 20.9375H18.1875Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 7.1875H18.875M13.375 10.625H18.875"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
