import { useRouter } from "next/router";
import { FC } from "react";

import { LinkButton } from "./LinkButton";
import { Navigate } from "../features/Navigate";
import { pagesPath } from "../lib/$path";

export const LoginButton: FC = () => {
  const { asPath } = useRouter();
  /**
   * decodeしておく
   * https://github.com/balus-co-ltd/spwn-portal/pull/118#discussion_r1065357469
   */
  const redirectTo = decodeURIComponent(asPath);
  const path = pagesPath.login.$url({ query: { redirect_to: redirectTo } });

  /**
   * i18n
   * https://github.com/balus-co-ltd/spwn-portal/pull/118#discussion_r1065364152
   */
  return (
    <Navigate href={path}>
      <LinkButton size={{ base: "xs", md: "sm" }} variant={"outline"}>
        ログイン
      </LinkButton>
    </Navigate>
  );
};
