import { UrlObject } from "url";

import { FC } from "react";

import { IoCartOutline } from "@spwn-portal/icon/ionicons5";
import {
  Box,
  Center,
  Flex,
  Icon,
  Link,
  Spacer,
  Stack,
} from "@spwn-portal/ui/chakra";

import { AccountMenu } from "./AccountMenu";
import { Navigate } from "./Navigate";
import { pagesPath } from "./path";
import { useGetGoodsCountInCarts } from "./useGetGoodsCountInCarts";
import { isEnableSelfShippingGoods } from "../constants/featureFlag";
import { useAuthContext, User } from "../contexts/auth";
import { useBrandContext } from "../contexts/brand";
import { LoginButton } from "../ui/LoginButton";
import { SignUpButton } from "../ui/SignUpButton";
import { SpwnLogo } from "../ui/SpwnLogo";

/**
 * ヘッダー
 *
 * ログイン状態によってUI変わるなどはfeatureな挙動と判断し、feature UIとしている
 * HeaderLayout がuiにあってもいいかも
 */

export const Header = () => {
  const auth = useAuthContext();
  const { brandSlug, tenantId } = useBrandContext();
  /**
   * brandSlugが存在していたらbrandのTOPに遷移する
   * brandSlugが存在してなければ、spwn.jpに遷移する
   *
   * @todo
   * brandSlugが存在してなければ、最後に見ていたbrandのTOPに遷移する。
   * 最後に見ていたbrandSlugをlocal-storageに入れる？
   */
  const href = brandSlug
    ? pagesPath._brandSlug(brandSlug).$url()
    : pagesPath.$url();

  return auth.type === "authorized" ? (
    <LoggedInHeaderWithDependency
      spwnLogoHref={href}
      user={auth.user}
      isEnableSelfShippingGoods={isEnableSelfShippingGoods(tenantId)}
    />
  ) : (
    <NotLoggedInHeader spwnLogoHref={href} />
  );
};

// 非ログイン時のヘッダー
type NotLoggedInHeaderProps = {
  spwnLogoHref: UrlObject;
};
const NotLoggedInHeader: FC<NotLoggedInHeaderProps> = ({ spwnLogoHref }) => (
  <HeaderLayout
    left={<SpwnLogoLink spwnLogoHref={spwnLogoHref} />}
    right={<UserLeadButtons />}
  />
);

const UserLeadButtons: FC = () => {
  return (
    <Stack direction={"row"} spacing={3}>
      <LoginButton />
      <SignUpButton />
    </Stack>
  );
};

type LoggedInHeaderWithDependencyProps = {
  spwnLogoHref: UrlObject;
  user: User;
  isEnableSelfShippingGoods: boolean;
};
const LoggedInHeaderWithDependency: FC<LoggedInHeaderWithDependencyProps> = ({
  spwnLogoHref,
  user,
  isEnableSelfShippingGoods,
}) => {
  const { data } = useGetGoodsCountInCarts();

  return (
    <LoggedInHeader
      spwnLogoHref={spwnLogoHref}
      user={user}
      goodsCountInCarts={data?.goodsCount ?? 0} // useGetGoodsCountInCartsの中でauth取得するようにしたいので、LoggedInHeaderWithDependencyコンポーネント自体不要かも
      isEnableSelfShippingGoods={isEnableSelfShippingGoods}
    />
  );
};

// ログイン時のヘッダー
type LoggedInHeaderProps = {
  spwnLogoHref: UrlObject;
  user: User;
  goodsCountInCarts: number;
  isEnableSelfShippingGoods: boolean;
};
export const LoggedInHeader: FC<LoggedInHeaderProps> = ({
  spwnLogoHref,
  user,
  goodsCountInCarts,
  isEnableSelfShippingGoods,
}) => {
  const hasGoodsInCarts = goodsCountInCarts > 0;

  return (
    <HeaderLayout
      left={<SpwnLogoLink spwnLogoHref={spwnLogoHref} />}
      right={
        <Flex direction={"row"} gap={4} alignItems={"center"}>
          {/* TODO: グッズ機能正式リリースまでは、カートに商品入っていない限りカートアイコンを隠す */}
          {hasGoodsInCarts && <Cart hasGoods={hasGoodsInCarts} />}
          <AccountMenu
            user={user}
            isEnableSelfShippingGoods={isEnableSelfShippingGoods}
          />
        </Flex>
      }
    />
  );
};

type CartProps = {
  hasGoods: boolean;
};
const Cart: FC<CartProps> = ({ hasGoods }) => (
  <Navigate href={pagesPath.account.cart.$url()}>
    <Link>
      <Center position={"relative"}>
        <Icon as={IoCartOutline} boxSize={8} color={"icon.ondark"} />
        {hasGoods && (
          <Box
            bg={"icon.critical"}
            boxSize={3}
            borderRadius={"full"}
            position={"absolute"}
            top={0}
            right={0}
          />
        )}
      </Center>
    </Link>
  </Navigate>
);

type HeaderLayoutProps = {
  left: JSX.Element;
  right: JSX.Element;
};
const HeaderLayout: FC<HeaderLayoutProps> = ({ left, right }) => (
  <Box
    backgroundColor={"black"}
    borderBottomStyle={"solid"}
    borderColor={"gray.300"}
    shadow={"md"}
    px={{ base: 5, md: 8 }}
    py={{ base: "9px", md: "14px" }}
  >
    <Flex alignItems={"center"}>
      {left}
      <Spacer />
      {right}
    </Flex>
  </Box>
);

type SpwnLogoLinkProps = {
  spwnLogoHref: UrlObject;
};
const SpwnLogoLink: FC<SpwnLogoLinkProps> = ({ spwnLogoHref }) => (
  <Navigate href={spwnLogoHref}>
    <Box as={"a"} _hover={{ cursor: "pointer" }}>
      <SpwnLogo height={{ base: "18px", md: "22px" }} width={"auto"} />
    </Box>
  </Navigate>
);
