import { signOut } from "firebase/auth";
import { useCallback } from "react";

import { callLogout } from "@spwn-portal/bff/src";

import { NEXT_PUBLIC_BACKEND_API_URL } from "../constants/env";
import { useConfig } from "../contexts/config";
import { useFirebase } from "../contexts/firebase";

export const useLogout = (): (() => Promise<void>) => {
  const { firebase } = useFirebase();

  const {
    config: { emulators },
  } = useConfig();
  const useEmulator = emulators !== undefined;

  /**
   * ローカル開発環境用のログアウト
   */
  const logout = useCallback(async () => {
    await signOut(firebase.auth);
  }, [firebase.auth]);

  /**
   * logout API（すべてのアプリケーションからログアウトする）によるログアウト
   * FIXME ログアウト後にリダイレクトするなどの調整はするかも
   */
  const logoutWithAPI = useCallback(async () => {
    await callLogout(NEXT_PUBLIC_BACKEND_API_URL, firebase.auth, {});
    /**
     * onCall logout でログアウトされるが、onAuthStateChanged で検知されないのでリロードする
     */
    window.location.assign(window.location.href);
  }, [firebase.auth]);

  return process.env.NODE_ENV === "development" && useEmulator
    ? logout
    : logoutWithAPI;
};
