import { FC } from "react";

import { Icon, IconProps } from "@spwn-portal/ui/chakra";

export const PaymentHistoryIcon: FC<IconProps> = (props) => {
  return (
    <Icon
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>PaymentHistoryIcon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9424 9.67603L13.6791 12.5837L12.4154 9.67603H10.4199L12.2771 13.457H11.2904V14.372H12.7337V14.7835H11.2904V15.6976H12.7337V17.0124H14.6241V15.6976H16.067V14.7835H14.6241V14.372H16.067V13.457H15.0802L16.9487 9.67603H14.9424Z"
        fill="black"
        fillOpacity="0.92"
      />
      <mask
        id="mask0_857_50649"
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="24"
        height="22"
      >
        <path d="M0.75 1.21899H23.2498V22.7814H0.75V1.21899Z" fill="white" />
      </mask>
      <g mask="url(#mask0_857_50649)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.572 20.7635C9.35133 20.7635 5.91774 17.3407 5.91774 13.1337C5.91774 8.92618 9.35133 5.50337 13.572 5.50337C17.7926 5.50337 21.2257 8.92618 21.2257 13.1337C21.2257 17.3407 17.7926 20.7635 13.572 20.7635ZM2.7743 10.8663C2.7743 6.65931 6.20743 3.23649 10.4281 3.23649C11.1645 3.23649 11.8751 3.34665 12.5491 3.54118C7.69196 4.05212 3.89415 8.1579 3.89415 13.1337C3.89415 14.0777 4.03665 14.9895 4.29165 15.8524C4.22602 15.6298 4.16649 15.4034 4.11633 15.1742C3.27071 13.947 2.7743 12.4643 2.7743 10.8663ZM17.5366 4.34181V4.34228C15.7671 2.42743 13.2396 1.21899 10.4281 1.21899C5.09133 1.21899 0.749771 5.54649 0.749771 10.8663C0.749771 14.7771 3.10055 18.1451 6.46337 19.6587C8.23337 21.5731 10.7609 22.7815 13.572 22.7815C18.9087 22.7815 23.2498 18.4535 23.2498 13.1337C23.2498 9.2229 20.8999 5.85493 17.5366 4.34181Z"
          fill="black"
          fillOpacity="0.92"
        />
      </g>
    </Icon>
  );
};
