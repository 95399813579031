import { FC } from "react";

import { Icon, IconProps } from "@spwn-portal/ui/chakra";

export const LogoutIcon: FC<IconProps> = (props) => {
  return (
    <Icon
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>LogoutIcon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0312 3.09375C17.0312 2.57625 16.6112 2.15625 16.0938 2.15625H3.90625C3.38875 2.15625 2.96875 2.57625 2.96875 3.09375V20.9062C2.96875 21.4237 3.38875 21.8438 3.90625 21.8438H16.0938C16.6112 21.8438 17.0312 21.4237 17.0312 20.9062C17.0312 20.8659 17.0242 20.828 17.0195 20.7891C17.0242 20.7502 17.0312 20.7122 17.0312 20.6719V17.3906C17.0312 16.8731 16.6112 16.4531 16.0938 16.4531C15.5763 16.4531 15.1562 16.8731 15.1562 17.3906V19.9688H4.84375V4.03125H15.1562V6.60938C15.1562 7.12687 15.5763 7.54688 16.0938 7.54688C16.6112 7.54688 17.0312 7.12687 17.0312 6.60938V3.32812C17.0312 3.28781 17.0242 3.24984 17.0195 3.21094C17.0242 3.17203 17.0312 3.13406 17.0312 3.09375Z"
        fill="black"
        fillOpacity="0.92"
      />
      <mask
        id="mask0_857_50665"
        maskUnits="userSpaceOnUse"
        x="10"
        y="8"
        width="13"
        height="8"
      >
        <path
          d="M10.4685 8.71875H22.6563V15.2809H10.4685V8.71875Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_857_50665)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4507 11.4142L20.5757 9.07045C20.2527 8.66639 19.6621 8.60077 19.2581 8.9242C18.8535 9.24717 18.7884 9.83733 19.1118 10.2414L19.7681 11.0622H11.406C10.8885 11.0622 10.4685 11.4822 10.4685 11.9997C10.4685 12.5176 10.8885 12.9372 11.406 12.9372H19.7681L19.1118 13.758C18.7884 14.162 18.8535 14.7522 19.2581 15.0756C19.431 15.2134 19.6377 15.2809 19.8435 15.2809C20.1177 15.2809 20.3906 15.1605 20.5757 14.9289L22.4507 12.5851C22.7249 12.243 22.7249 11.7564 22.4507 11.4142Z"
          fill="black"
          fillOpacity="0.92"
        />
      </g>
    </Icon>
  );
};
