import { PortalOrderService } from "@spwn-portal/grpc";

import { useAuthContext } from "../contexts/auth";
import { useSWR } from "../contexts/swr";
import { useConnectClient } from "../lib/connect";

const DEFAULT_RESULT: { goodsCount: number } = { goodsCount: 0 };

export const useGetGoodsCountInCarts = () => {
  const client = useConnectClient(PortalOrderService);
  const auth = useAuthContext();

  // 非ログイン時はリクエストを送らない
  return useSWR<typeof DEFAULT_RESULT>(
    auth.type === "authorized"
      ? { key: "getGoodsCountInCarts", userId: auth.user.uid }
      : null,
    async () => {
      const { carts } = await client.getCarts({});
      return {
        goodsCount: carts.flatMap((cart) => cart.items).length,
      };
    },
    { fallbackData: DEFAULT_RESULT }
  );
};
