import { useRouter } from "next/router";
import { FC, useMemo } from "react";

import { LinkButton } from "./LinkButton";
import { useConfig } from "../contexts/config";
import { Navigate } from "../features/Navigate";

export const SignUpButton: FC<{
  variant?: "primary" | "outline";
}> = ({ variant = "primary" }) => {
  const { config } = useConfig();
  const { asPath } = useRouter();

  const signupUrl = useMemo(() => {
    const accountsAppUrl = config.app.accountsAppUrl;
    const fanclubUrl = config.app.fanclubUrl;
    const currentUrl = new URL(asPath, fanclubUrl).toString();

    return new URL(
      currentUrl
        ? `${accountsAppUrl}/signup?return_url=${encodeURIComponent(
            currentUrl
          )}`
        : `${accountsAppUrl}/signup`,
      config.vercel.environment === "development"
        ? "http://localhost:3000/"
        : accountsAppUrl
    );
  }, [
    config.vercel.environment,
    asPath,
    config.app.accountsAppUrl,
    config.app.fanclubUrl,
  ]);

  /**
   * baseURLが取り除かれているので、Storybookでエラーになる。
   * なので、new URLの引数にbaseURLを追加してあげる。
   * @see https://github.com/storybookjs/storybook/issues/10227#issuecomment-604871758
   */
  return (
    <Navigate href={signupUrl}>
      <LinkButton size={{ base: "xs", md: "sm" }} variant={variant}>
        新規登録
      </LinkButton>
    </Navigate>
  );
};
