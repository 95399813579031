import { FC } from "react";

import { Image, LayoutProps } from "@spwn-portal/ui/chakra";

type SpwnLogoProps = {
  height?: LayoutProps["height"];
  width?: LayoutProps["height"];
};

export const SpwnLogo: FC<SpwnLogoProps> = ({ height, width }) => {
  return (
    <Image
      src={"/images/spwn-logo.png"}
      alt={"SPWN Logo"}
      height={height}
      width={width}
    />
  );
};
