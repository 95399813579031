import { FC } from "react";

import { Icon } from "@spwn-portal/ui/chakra";

type SpwnPortalLogoProps = {
  variant: "oneLine" | "twoLine";
  size?: "sm";
};

const sizeWidthMap = {
  sm: "130px",
};

export const SpwnPortalLogo: FC<SpwnPortalLogoProps> = ({
  variant,
  size = "sm",
}) => {
  return variant === "oneLine" ? (
    <OneLineSpwnPortalLogo size={size} />
  ) : (
    <TwoLineSpwnPortalLogo size={size} />
  );
};

type OneLineSpwnPortalLogoProps = {
  size: "sm";
};

const OneLineSpwnPortalLogo: FC<OneLineSpwnPortalLogoProps> = ({ size }) => {
  return (
    <Icon
      viewBox="107.81 262.45 3257.11 270.41"
      height={"auto"}
      width={sizeWidthMap[size]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>SpwnPortalLogo</title>
      <g>
        <path d="M164.74,324.12h382.71v-56.93H129.23c-11.83,0-21.42,9.59-21.42,21.42v99.04c0,11.29,8.76,20.64,20.02,21.38l365.85,23.99v33.43H110.97v56.93h418.22c11.83,0,21.42-9.59,21.42-21.42v-102.2c0-11.29-8.76-20.64-20.02-21.38l-365.85-23.99v-30.27Z" />
        <path d="M631.26,264.03h-24.44c-11.83,0-21.42,9.59-21.42,21.42v244.26h56.93v-66.12l342.86-34.28c10.95-1.1,19.29-10.31,19.29-21.32v-122.54c0-11.83-9.59-21.42-21.42-21.42h-351.8Zm316.29,56.93v54.89l-305.22,30.52v-85.42h305.22Z" />
        <path d="M1342.92,464.71v-177.67c0-11.83-9.59-21.42-21.42-21.42h-35.47c-6.05,0-11.83,2.56-15.89,7.05l-173.91,192.21-.02-202.43h-56.93v259.36h82.22l164.49-181.8v181.8h82.38l217.63-256.66h-71.43l-171.64,199.56Z" />
        <path d="M1989.73,264.03v199.26h-13.33l-281.7-192.91c-6.05-4.14-13.2-6.36-20.53-6.36h-52.05c-11.83,0-21.42,9.59-21.42,21.42v247.42h56.93v-211.91h10.17l285.51,195.51c3.56,2.44,7.78,3.75,12.1,3.75h59.83c11.83,0,21.42-9.59,21.42-21.42v-234.77h-56.93Z" />
      </g>
      <g>
        <path d="M3364.92,529.41h-39.78v-254h39.78v254Z" />
        <path d="M3240.71,421.91c0-6.51,.13-12.47-.13-18.42-.04-.99-1.62-2.34-2.77-2.76-1.32-.49-2.96-.15-4.46-.15-49.83,0-99.66,0-149.48,0-1.96,0-3.92,0-5.83,0-1.48-19.12,16.02-36.92,36.38-37.11,24.66-.22,49.33-.06,73.99-.06,15.66,0,31.35,.54,46.99-.13,23.4-.99,40.72,17.68,40.54,40.52-.29,35.49-.43,70.99-.63,106.49-.03,5.82,0,11.65,0,18.28-2.46,0-4.54,0-6.63,0-53.99-.05-107.99-.11-161.98-.16-17.74-.02-32.2-13.74-32.68-31.65-.39-14.65-.4-29.34-.02-43.99,.4-15.6,13.53-29.04,29.14-30.52,2.65-.25,5.32-.33,7.98-.33,41-.02,81.99-.01,122.99-.01,1.96,0,3.93,0,6.6,0Zm-.72,37.35h-130.52v31.86h130.52v-31.86Z" />
        <path d="M2165.09,363.51c1.8-.08,3.42-.22,5.04-.22,65.3-.01,130.6-.02,195.9,0,13.85,0,23.06,5.35,27.17,16.26,1.31,3.49,1.83,7.44,1.91,11.19,.26,11.99,.19,24.28,.14,36.28-.06,15.2-7.86,24.72-22.8,27.55-54.1,10.24-108.21,20.43-162.35,30.46-3.65,.68-4.5,2.01-4.45,5.46,.2,12.12,.08,26.83,.08,39.23h-40.64v-166.21Zm40.87,86.84c19.9-3.72,39.18-7.32,58.46-10.93,30.41-5.68,60.82-11.33,91.2-17.14,1.36-.26,3.42-1.94,3.47-3.04,.32-6.76,.16-13.85,.16-20.82h-153.3v51.92Z" />
        <path d="M2523.88,529.6c-19.66,0-39.33,.03-58.99,0-23.6-.04-39.88-16.21-39.95-39.82-.08-29.16-.04-58.48-.01-87.64,.02-21.19,15.98-38.34,37.12-38.54,41.49-.4,82.99-.42,124.48,0,21.38,.22,36.77,16.91,37.07,39.73,.38,28.99,.28,58.15,.02,87.14-.2,22.4-17.16,39.81-40.25,39.24-19.82-.49-39.66-.1-59.49-.1Zm.57-129.16c-19.48,0-38.97,.12-58.45-.09-4.58-.05-6.04,1.3-6.01,5.96,.2,26.81,.17,53.78,.01,80.59-.02,4.21,.94,5.81,5.52,5.79,39.13-.17,78.27-.15,117.4-.02,4.07,.01,5.47-1.09,5.45-5.3-.16-27.14-.15-54.44-.01-81.59,.02-4.14-1.32-5.46-5.47-5.42-19.48,.18-38.97,.08-58.45,.08Z" />
        <path d="M2894.79,529.72v-124.32h-40.67v-41.71h40.63v-34.77h39.64v34.49h111.9v41.73h-111.37v85.21h100.92v39.38h-141.05Z" />
        <path d="M2822.5,363.68v35.24h-5.99c-29.98,0-59.96,.05-89.95-.06-3.41-.01-5.91,.85-8.3,3.4-6.85,7.27-13.89,14.37-21.04,21.35-2.32,2.27-3.26,4.59-3.25,7.85,.11,30.65,.07,61.3,.07,91.95v6.31h-39.76v-166.05h39.61v11.08c.34,.27,.69,.54,1.03,.8,3.17-3.17,6.18-6.53,9.58-9.43,1.6-1.36,3.95-2.58,5.97-2.59,36.14-.15,72.29-.11,108.44-.1,1.12,0,2.25,.16,3.6,.26Z" />
      </g>
    </Icon>
  );
};

type TwoLineSpwnPortalLogoProps = {
  size: "sm";
};

const TwoLineSpwnPortalLogo: FC<TwoLineSpwnPortalLogoProps> = ({ size }) => {
  return (
    <Icon
      viewBox="101.64 265.92 2730 756.3"
      height={"auto"}
      width={sizeWidthMap[size]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>SpwnPortalLogo</title>
      <path d="M2755.72,267.17v285.13h-19.01l-399.36-278.31c-6.37-4.44-13.94-6.82-21.71-6.82h-68.89c-20.97,0-37.96,17-37.96,37.96v323.08h75.92V343.1h19.01l399.36,278.31c6.37,4.43,13.94,6.81,21.71,6.81h68.89c20.97,0,37.96-16.99,37.96-37.96V267.17h-75.92Zm-1416.4,0H810.59c-18.58,2.46-32.94,18.33-32.94,37.59v323.46h75.92v-78.69l485.17-56.88c19.11-2.26,33.51-18.45,33.51-37.7v-150.18c0-19.26-14.35-35.13-32.94-37.59Zm-42.99,154.04l-442.76,51.87v-130.36h442.76v78.49Zm-605.31,10.02l-513.47-39.59v-48.64H715.91v-75.83H137.78c-20.11,.96-36.14,17.52-36.14,37.87v121.7c0,19.81,15.24,36.29,34.99,37.85l513.47,39.59v48.64H107.79v75.4H694.04c18.11-2.88,31.97-18.52,31.97-37.44v-121.7c0-19.81-15.24-36.29-34.99-37.85Zm1158.52,120.38v-247.73c0-20.97-16.99-37.96-37.96-37.96h-42.82c-10.95,0-21.36,4.73-28.56,12.96l-238.94,273.02V267.17h-75.92v323.09c0,20.97,17,37.96,37.96,37.96h54.84c10.95,0,21.36-4.72,28.56-12.96l226.92-259.27v234.28c0,20.97,16.99,37.96,37.96,37.96h54.84c11.09,0,21.61-4.84,28.83-13.26l298.05-347.79h-99.99l-243.76,284.45Z" />
      <g>
        <path d="M2492.63,946.89v-229.33h-73.26v265.96c0,18.28,13.67,33.88,31.79,36.28l.4,.05h328.98v-72.96h-287.91Z" />
        <polygon points="1762.25 717.1 1753.26 717.1 1552.16 717.1 1552.16 790.06 1716.49 790.06 1716.49 1019.39 1716.59 1019.39 1789.75 1019.39 1789.85 1019.39 1789.85 790.06 1794.43 790.06 1954.18 790.06 1954.18 717.1 1762.25 717.1" />
        <path d="M968.51,716.47c-83.84-.85-167.69-.83-251.53-.01-42.71,.41-74.96,35.07-75,77.88-.06,58.93-.14,88.24,.02,147.17,.13,47.7,33.03,80.38,80.72,80.46,39.73,.07,79.47,.01,119.2,.01,40.07,0,80.16-.79,120.21,.21,46.66,1.16,80.93-31.19,81.34-76.45,.53-58.59,.72-90.41-.05-148.99-.6-46.1-31.71-79.84-74.91-80.28Zm-7.27,230.9c-79.07-.27-158.15-.31-237.22,.04-9.26,.04-11.21-3.2-11.16-11.7,.32-54.17,.37-78.74-.03-132.91-.07-9.41,2.88-12.15,12.13-12.05,39.37,.43,78.74,.18,118.11,.18,39.37,0,78.74,.2,118.11-.16,8.38-.08,11.09,2.59,11.04,10.96-.28,54.85-.3,80.08,.02,134.93,.05,8.52-2.78,10.74-11.01,10.71Z" />
        <path d="M574.19,910.86c17.96-2.12,31.5-17.36,31.5-35.44v-122.93c0-17.81-13.31-33.01-30.97-35.35l-.31-.04H204.42l-.31,.04c-17.66,2.34-30.97,17.54-30.97,35.35v269.09h71.38v-72.06l329.66-38.65Zm-329.66-33.22v-89.47h289.78v55.52l-289.78,33.95Z" />
        <path d="M1513.27,1008.56h.01l-123.54-85.98,99.96-11.72c17.96-2.12,31.5-17.36,31.5-35.44v-122.93c0-17.81-13.31-33.01-30.97-35.35l-.31-.04h-369.99l-.31,.04c-17.66,2.34-30.97,17.54-30.97,35.35v269.09h71.38v-72.06l122.79-14.4,96.21,67.05c.06,.04,.13,.09,.19,.13l17.41,12.55c6.01,4.18,13.05,6.39,20.36,6.39h113.87l-17.6-12.67Zm-353.23-130.92v-89.47h289.78v55.52l-289.78,33.95Z" />
        <path d="M2313.65,1019.86h87l-182.01-288.05c-3.54-5.6-8.52-10.14-14.42-13.14-2.02-1.03-4.32-1.57-6.63-1.57h-106.49c-2.31,0-4.61,.54-6.63,1.57-5.9,2.99-10.89,7.54-14.42,13.14l-182.01,288.06h87l34.3-54.35h270.03l34.3,54.35Zm-144.8-229.46l64.25,101.81h-177.52l64.25-101.81h49.02Z" />
      </g>
    </Icon>
  );
};
