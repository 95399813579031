import { Button, ButtonProps, forwardRef } from "@spwn-portal/ui/chakra";

/**
 * LinkButtonの責務
 * - 遷移とスタイルで責務分ける。features/Navigateは遷移のみ行いスタイルは当てていない。
 * - このコンポーネントは、リンクで使用するButtonとして定義する。
 *
 * @example
 * <Navigate href={path}>
 *   <LinkButton size={"md"} variant="primary">
 *     ログイン
 *   </LinkButton>
 * </Navigate>
 */
export const LinkButton = forwardRef<ButtonProps, "a">(
  ({ children, ...props }, ref) => {
    return (
      <Button as={"a"} fontSize={"sm"} cursor={"pointer"} ref={ref} {...props}>
        {children}
      </Button>
    );
  }
);
