import { FC } from "react";

import { Avatar, AvatarProps } from "@spwn-portal/ui/chakra";

type ProfileIconProps = {
  src?: string | undefined;
};

/**
 * アバターのアイコン
 *
 * FIXME ブランドプロフィールのアイコンこっち使ってもいい？
 * https://github.com/balus-co-ltd/spwn-portal/blob/37bf9c139fd4b2d4dd3cc9aa9a9890e3b56c5022/apps/portal/src/features/BrandProfile.tsx#L51-L55
 */
export const AvatarIcon: FC<ProfileIconProps & AvatarProps> = (props) => {
  return <Avatar {...props} />;
};
