import { VFC } from "react";

import { Flex, Icon } from "@spwn-portal/ui/chakra";

export const ClipboardIcon: VFC = () => {
  return (
    <Flex
      width={"24px"}
      height={"24px"}
      rounded={"full"}
      backgroundColor={"action.primary"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Icon
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={"14px"}
        height={"14px"}
      >
        <title>ClipboardSVGIcon</title>
        <g clipPath="url(#clip0_857_50620)">
          <path
            d="M11.1339 4.495H5.0636C4.20281 4.495 3.505 5.19281 3.505 6.0536V12.1239C3.505 12.9847 4.20281 13.6825 5.0636 13.6825H11.1339C11.9947 13.6825 12.6925 12.9847 12.6925 12.1239V6.0536C12.6925 5.19281 11.9947 4.495 11.1339 4.495Z"
            stroke="white"
            strokeWidth="1.2"
            strokeLinejoin="round"
          />
          <path
            d="M10.4913 4.495L10.505 3.83875C10.5039 3.43299 10.3422 3.04419 10.0552 2.75727C9.76832 2.47036 9.37951 2.30866 8.97375 2.3075H3.0675C2.6038 2.30887 2.15947 2.49369 1.83158 2.82158C1.50369 3.14947 1.31888 3.5938 1.3175 4.0575V9.96375C1.31866 10.3695 1.48036 10.7583 1.76727 11.0452C2.05419 11.3321 2.443 11.4938 2.84875 11.495H3.505"
            stroke="white"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_857_50620">
            <rect
              width="14"
              height="14"
              fill="white"
              transform="translate(0.00500488 0.995003)"
            />
          </clipPath>
        </defs>
      </Icon>
    </Flex>
  );
};
