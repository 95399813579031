import NextLink from "next/link";

import { PagesPath, pagesPath } from "../lib/$path";

import type { FC, ReactNode } from "react";
import type { UrlObject } from "url";

type Props = {
  href: UrlObject | ((path: PagesPath) => UrlObject);
  children: ReactNode;
};

/**
 * pathpidaを利用したNavigator
 * 
 * 参考
 * - https://zenn.dev/hisho/books/617d8f9d6bd78b/viewer/chapter13
 * 
 * NOTE
 * - NextLinkを使用するとaタグがレンダリングされるようになっている
 *   - https://github.com/vercel/next.js/pull/36436
 * - しかし、ChakraUIコンポーネントの場合付与されないので明示的にaタグであることを指定する必要がある
 *   - 指定しなくても動くが、指定したほうがリンクがhoverでプレビューされる、SEO的にもいい
 * - NavigateコンポーネントではChakraに依存しないことにした
 *
 * // FIXME: pathpidaを利用したfunction渡しを行なっている箇所は改良の余地あり
 * https://github.com/balus-co-ltd/spwn-portal/pull/180#discussion_r1086105701
 * 
 * @example
 * ```
  <Navigate href={(path) => path.$url()}>
    <Button as={"a"}>
      リンク
    </Button>
  </Navigate>
 * ```
 * @example
  const path = pagesPath.$url()
  return (
    <Navigate href={path}>
      <Button as={"a"}>
        リンク
      </Button>
    </Navigate>
  );
 */
export const Navigate: FC<Props> = ({ href, children }) => {
  return (
    <NextLink
      href={typeof href === "function" ? href(pagesPath) : href}
      passHref
    >
      {children}
    </NextLink>
  );
};
