import { createContext, FC, useContext } from "react";

import { BrandMetadataDTO } from "@spwn-portal/bff/src/dto/brandMetadata";

type BrandContextValue = BrandMetadataDTO;

const BrandContext = createContext<BrandContextValue>({} as BrandContextValue);

/**
 * /[brandSlug] 以下のページでブランドのメタデータを提供する
 */
export const BrandContextProvider: FC<{
  value: BrandMetadataDTO;
  children: React.ReactNode;
}> = ({ value, children }) => {
  return (
    <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
  );
};

/**
 * /[brandSlug] 以下のページでブランドのメタデータを取得できる
 */
export const useBrandContext = (): BrandContextValue =>
  useContext(BrandContext);
