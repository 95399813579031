import { FC } from "react";

import { Icon, IconProps } from "@spwn-portal/ui/chakra";

export const ProfileIcon: FC<IconProps> = (props) => {
  return (
    <Icon
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>ProfileIcon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.94375C7.00641 2.94375 2.94375 7.00687 2.94375 12C2.94375 16.9936 7.00641 21.0567 12 21.0567C16.9931 21.0567 21.0562 16.9936 21.0562 12C21.0562 7.00687 16.9931 2.94375 12 2.94375ZM12 22.7812C6.05531 22.7812 1.21875 17.9452 1.21875 12C1.21875 6.05531 6.05531 1.21875 12 1.21875C17.9452 1.21875 22.7812 6.05531 22.7812 12C22.7812 17.9452 17.9452 22.7812 12 22.7812Z"
        fill="black"
        fillOpacity="0.92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.94375C7.00641 2.94375 2.94375 7.00687 2.94375 12C2.94375 16.9936 7.00641 21.0567 12 21.0567C16.9931 21.0567 21.0562 16.9936 21.0562 12C21.0562 7.00687 16.9931 2.94375 12 2.94375ZM12 22.7812C6.05531 22.7812 1.21875 17.9452 1.21875 12C1.21875 6.05531 6.05531 1.21875 12 1.21875C17.9452 1.21875 22.7812 6.05531 22.7812 12C22.7812 17.9452 17.9452 22.7812 12 22.7812Z"
        stroke="black"
        strokeOpacity="0.92"
        strokeWidth="0.25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.96211 20.1561C5.48586 20.1561 5.09961 19.7699 5.09961 19.2931C5.09961 16.5041 7.13023 14.3595 10.2732 13.8289C10.742 13.7483 11.1877 14.0656 11.2674 14.5353C11.3466 15.005 11.0302 15.4503 10.5605 15.5291C7.80617 15.9946 6.82461 17.7786 6.82461 19.2931C6.82461 19.7699 6.43836 20.1561 5.96211 20.1561Z"
        fill="black"
        fillOpacity="0.92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0378 20.1565C17.5616 20.1565 17.1753 19.7702 17.1753 19.2935C17.1753 17.779 16.1938 15.9949 13.4394 15.5294C12.9697 15.4507 12.6533 15.0054 12.733 14.5357C12.8117 14.066 13.257 13.7472 13.7267 13.8293C16.8697 14.3599 18.9003 16.5044 18.9003 19.2935C18.9003 19.7702 18.5145 20.1565 18.0378 20.1565Z"
        fill="black"
        fillOpacity="0.92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9992 7.14297C10.097 7.14297 8.54922 8.69078 8.54922 10.593C8.54922 12.4956 10.097 14.0434 11.9992 14.0434C13.9019 14.0434 15.4492 12.4956 15.4492 10.593C15.4492 8.69078 13.9019 7.14297 11.9992 7.14297ZM11.9992 15.768C9.14594 15.768 6.82422 13.4467 6.82422 10.593C6.82422 7.73922 9.14594 5.41797 11.9992 5.41797C14.853 5.41797 17.1742 7.73922 17.1742 10.593C17.1742 13.4467 14.853 15.768 11.9992 15.768Z"
        fill="black"
        fillOpacity="0.92"
      />
    </Icon>
  );
};
